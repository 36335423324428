/* eslint-disable no-inner-declarations */
// $(function () {
//   $.validator.addMethod(
//     "pwcheck",
//     function (value) {
//       return (
//         // eslint-disable-next-line no-useless-escape
//         /^([A-Za-z0-9\d=!\-@._*$#?%^&()+=\[\]~:;,<>]+\s?)*$/.test(value) &&
//         /[a-z]/.test(value) &&
//         /\d/.test(value) &&
//         // eslint-disable-next-line no-useless-escape
//         /[=!\-@._*$#?%^&()+=\[\]~:;,<>]/.test(value)
//       );
//     },
//     "Must contain at least one number, one letter, and one symbol"
//   );

//   $.validator.addMethod(
//     "commonsymbols",
//     function (value) {
//       return (
//         // eslint-disable-next-line no-useless-escape
//         /^([A-Za-z0-9\d!\-@,.$&+]+\s?)*$/.test(value)
//       );
//     },
//     "Only Alphanumeric and (!-@,.$&) are allowed"
//   );

//   $(".js--user-registration-form").validate({
//     debug: false,
//     rules: {
//       "user[accept_terms_and_conditions]": {
//         required: true,
//       },
//       "user[email]": {
//         maxlength: 60,
//         email: true,
//       },
//       "user[email_confirmation]": {
//         equalTo: "#user_email",
//       },
//       "user[password]": {
//         minlength: {
//           param: 8,
//         },
//         maxlength: {
//           param: 30,
//         },
//         required: true,
//         pwcheck: {
//           param: true,
//         },
//       },
//       "user[password_confirmation]": {
//         equalTo: "#user_password",
//       },
//       "user[first_name]": {
//         minlength: 2,
//         maxlength: 30,
//         required: true,
//       },
//       "user[last_name]": {
//         minlength: 2,
//         maxlength: 30,
//         required: true,
//       },
//       customer_name: {
//         minlength: 3,
//         maxlength: 30,
//         required: true,
//         commonsymbols: true,
//       },
//     },
//     messages: {
//       "user[email]": "Please provide an email address.",
//       "user[email_confirmation]": "Please confirm your email.",
//       "user[password]": {
//         required: "Please provide a password.",
//       },
//       "user[password_confirmation]": {
//         required: "Please confirm your password.",
//         equalTo: "Confirmation must match provided password.",
//       },
//       "user[first_name]": "Please provide your first name.",
//       "user[last_name]": "Please provide your last name.",
//       customer_name: {
//         required: "Please provide the legal name of your business.",
//       },
//       "user[accept_terms_and_conditions]": "Please read and accept the terms.",
//     },
//   });
// });

document.addEventListener("DOMContentLoaded", function () {
  const form = document.querySelector(".js--user-registration-form");

  if (form !== null) {
    const email = form.elements["user[email]"];
    const emailErrorLabel = document.createElement("label");
    emailErrorLabel.setAttribute("id", `${email.id}-error`);
    emailErrorLabel.style.display = "none";
    emailErrorLabel.classList.add("error");
    email.parentNode.insertBefore(emailErrorLabel, email.nextSibling);

    const emailConfirmation = form.elements["user[email_confirmation]"];
    const confirmationErrorLabel = document.createElement("label");
    confirmationErrorLabel.setAttribute("id", `${emailConfirmation.id}-error`);
    confirmationErrorLabel.style.display = "none";
    confirmationErrorLabel.classList.add("error");
    emailConfirmation.parentNode.insertBefore(
      confirmationErrorLabel,
      emailConfirmation.nextSibling
    );

    const password = form.elements["user[password]"];
    const passwordErrorLabel = document.createElement("label");
    passwordErrorLabel.setAttribute("id", `${password.id}-error`);
    passwordErrorLabel.style.display = "none";
    passwordErrorLabel.classList.add("error");
    password.parentNode.insertBefore(passwordErrorLabel, password.nextSibling);

    const passwordConfirmation = form.elements["user[password_confirmation]"];
    const pConfirmationErrorLabel = document.createElement("label");
    pConfirmationErrorLabel.setAttribute(
      "id",
      `${passwordConfirmation.id}-error`
    );
    pConfirmationErrorLabel.style.display = "none";
    pConfirmationErrorLabel.classList.add("error");
    passwordConfirmation.parentNode.insertBefore(
      pConfirmationErrorLabel,
      passwordConfirmation.nextSibling
    );

    const firstName = form.elements["user[first_name]"];
    const firstErrorLabel = document.createElement("label");
    firstErrorLabel.setAttribute("id", `${firstName.id}-error`);
    firstErrorLabel.style.display = "none";
    firstErrorLabel.classList.add("error");
    firstName.parentNode.insertBefore(firstErrorLabel, firstName.nextSibling);

    const lastName = form.elements["user[last_name]"];
    const lastErrorLabel = document.createElement("label");
    lastErrorLabel.setAttribute("id", `${lastName.id}-error`);
    lastErrorLabel.style.display = "none";
    lastErrorLabel.classList.add("error");
    lastName.parentNode.insertBefore(lastErrorLabel, lastName.nextSibling);

    // eslint-disable-next-line dot-notation
    const customerName = form.elements["customer_name"];
    // console.log(customerName);
    // console.log(customerName !== undefined);
    // if (customerName !== undefined) {
    const customerErrorLabel = document.createElement("label");
    customerErrorLabel.setAttribute("id", `customer_name-error`);
    customerErrorLabel.style.display = "none";
    customerErrorLabel.classList.add("error");
    if (customerName !== undefined) {
      customerName.parentNode.insertBefore(
        customerErrorLabel,
        customerName.nextSibling
      );
    }
    // }
    // console.log(customerErrorLabel);

    const termsAndConditions = document.getElementById(
      "user_accept_terms_and_conditions"
    );

    const termsLabel = document.querySelector(".terms-error-label");
    const termsErrorLabel = document.createElement("label");
    termsErrorLabel.setAttribute("id", `${termsAndConditions.id}-error`);
    termsErrorLabel.style.display = "none";
    termsErrorLabel.classList.add("error");
    termsLabel.appendChild(termsErrorLabel);

    function validateEmail() {
      if (!email.validity.valid) {
        if (!email.classList.contains("error")) {
          email.classList.add("error");
        }

        if (email.validity.valueMissing) {
          email.setCustomValidity("Please provide an email address.");
          emailErrorLabel.textContent = email.validationMessage;
          emailErrorLabel.style.display = "inline";
        } else if (email.validity.typeMismatch) {
          email.setCustomValidity("Invalid email address.");
          emailErrorLabel.textContent = email.validationMessage;
          emailErrorLabel.style.display = "inline";
        } else {
          email.setCustomValidity("");
        }
      } else {
        email.setCustomValidity("");
        email.classList.remove("error");
        emailErrorLabel.style.display = "none";
      }
    }

    function validateEmailConfirmation() {
      if (emailConfirmation.value !== email.value) {
        emailConfirmation.classList.add("error");
        emailConfirmation.setCustomValidity("Please confirm your email.");
        confirmationErrorLabel.textContent =
          emailConfirmation.validationMessage;
        confirmationErrorLabel.style.display = "inline";
      } else {
        emailConfirmation.setCustomValidity("");
        emailConfirmation.classList.remove("error");
        confirmationErrorLabel.style.display = "none";
      }
    }

    function validateComplexity(value) {
      return (
        // eslint-disable-next-line no-useless-escape
        // /^([A-Za-z0-9\d=!\-@._*$#?%^&()+=\[\]~:;,<>]+\s?)*$/.test(value) &&
        /[a-z]/.test(value) &&
        /\d/.test(value) &&
        // eslint-disable-next-line no-useless-escape
        /[=!\-@._*$#?%^&()+=\[\]~:;,<>]/.test(value)
      );
    }

    function validatePassword() {
      if (!password.validity.valid) {
        if (!password.classList.contains("error")) {
          password.classList.add("error");
        }

        let validityMessage = "";

        if (password.validity.valueMissing) {
          validityMessage += "Please provide a password.";
          passwordErrorLabel.textContent = password.validationMessage;
          passwordErrorLabel.style.display = "inline";
        }

        if (password.validity.tooShort) {
          validityMessage += "Password must be at least 8 characters.";
        }

        if (!validateComplexity(password.value)) {
          validityMessage +=
            "Must contain at least one number, one letter, and one symbol.";
        }

        password.setCustomValidity(validityMessage);
        passwordErrorLabel.textContent = password.validationMessage;
        passwordErrorLabel.style.display = "inline";
      } else {
        password.setCustomValidity("");
        password.classList.remove("error");
        passwordErrorLabel.style.display = "none";
      }
    }

    function validatePasswordConfirmation() {
      if (passwordConfirmation.value !== password.value) {
        passwordConfirmation.classList.add("error");
        passwordConfirmation.setCustomValidity(
          "Confirmation must match provided password."
        );
        pConfirmationErrorLabel.textContent =
          passwordConfirmation.validationMessage;
        pConfirmationErrorLabel.style.display = "inline";
      } else {
        passwordConfirmation.setCustomValidity("");
        passwordConfirmation.classList.remove("error");
        pConfirmationErrorLabel.style.display = "none";
      }
    }

    function validateFirstName() {
      if (!firstName.validity.valid) {
        if (firstName.validity.valueMissing) {
          firstName.setCustomValidity("Please provide your first name.");
          firstName.classList.add("error");
          firstErrorLabel.textContent = firstName.validationMessage;
          firstErrorLabel.style.display = "inline";
        } else {
          firstName.setCustomValidity("");
        }
      } else {
        firstName.setCustomValidity("");
        firstName.classList.remove("error");
        firstErrorLabel.style.display = "none";
      }
    }

    function validateLastName() {
      if (!lastName.validity.valid) {
        if (lastName.validity.valueMissing) {
          lastName.setCustomValidity("Please provide your last name.");
          lastName.classList.add("error");
          lastErrorLabel.textContent = lastName.validationMessage;
          lastErrorLabel.style.display = "inline";
        } else {
          lastName.setCustomValidity("");
        }
      } else {
        lastName.setCustomValidity("");
        lastName.classList.remove("error");
        lastErrorLabel.style.display = "none";
      }
    }

    function customerNamePattern(value) {
      return /^([A-Za-z0-9\d!\-@,.$&+]+\s?)*$/.test(value);
    }

    function validateCustomerName() {
      if (!customerName.validity.valid) {
        customerName.classList.add("error");
        if (customerName.validity.valueMissing) {
          customerName.setCustomValidity(
            "Please provide the legal name of your business."
          );

          customerErrorLabel.textContent = customerName.validationMessage;
          customerErrorLabel.style.display = "inline";
        } else if (!customerNamePattern(customerName.value)) {
          customerName.setCustomValidity(
            "Only Alphanumeric and (!-@,.$&) are allowed"
          );
          customerErrorLabel.textContent = customerName.validationMessage;
          customerErrorLabel.style.display = "inline";
        } else {
          customerName.setCustomValidity("");
          customerName.classList.remove("error");
        }
      } else {
        customerName.setCustomValidity("");
        customerName.classList.remove("error");
      }
    }

    function validateTermsAndConditions() {
      if (!termsAndConditions.checked) {
        termsAndConditions.setCustomValidity(
          "Please read and accept the terms."
        );
        termsErrorLabel.textContent = termsAndConditions.validationMessage;
        termsErrorLabel.style.display = "inline";
      } else {
        termsAndConditions.setCustomValidity("");
      }
    }

    email.addEventListener("input", validateEmail);
    emailConfirmation.addEventListener("input", validateEmailConfirmation);
    password.addEventListener("input", validatePassword);
    passwordConfirmation.addEventListener(
      "input",
      validatePasswordConfirmation
    );
    firstName.addEventListener("input", validateFirstName);
    lastName.addEventListener("input", validateLastName);
    if (customerName !== undefined) {
      customerName.addEventListener("input", validateCustomerName);
    }
    termsAndConditions.addEventListener("input", validateTermsAndConditions);

    form.addEventListener("submit", function (event) {
      validateEmail();
      validateEmailConfirmation();
      validatePassword();
      validatePasswordConfirmation();
      validateFirstName();
      validateLastName();
      if (customerName !== undefined) {
        validateCustomerName();
      }
      validateTermsAndConditions();
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }
});
